
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    FilterFormInterface,
    ResponseDataStringArray as rdsa
} from "@/interfaces";

import * as types from "@/store/types";
import csdMes from "@/utils/VibrationMotors/CSD_meses.json";
import csdMesZoom from "@/utils/VibrationMotors/CSD_meses_zoom.json";
import csdDia from "@/utils/VibrationMotors/CSD_dia.json";
import csdNoche from "@/utils/VibrationMotors/CSD_noche.json";
import csdDesq from "@/utils/VibrationMotors/deseq_time.json";
import csdMueNoche from "@/utils/VibrationMotors/CSD_muestras_noche.json";
import csdMueNocheDesq from "@/utils/VibrationMotors/muestras_noche_deseq.json";
import csdDesqual1 from "@/utils/VibrationMotors/Desequilibrio_1.json";
import csdDesqual2 from "@/utils/VibrationMotors/Desequilibrio_2.json";
import csdDesqual3 from "@/utils/VibrationMotors/Desequilibrio_3.json";
import csdCIE1 from "@/utils/VibrationMotors/motor_CIE_Legazpi_1.json";
import csdCIE2 from "@/utils/VibrationMotors/motor_CIE_Legazpi_2.json";
import { TOKEN } from "@/services/auth/auth-service";

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Overview extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormInterface = {
        company: "",
        client: "",
        installation: "",
        modbus: "",
        valid: true,
        box: "",
        element: "",
        periodType: "daily",
        start: undefined,
        end: undefined,
        window: "1h",
        aggregation: "mean",
        comparative: false,
        days: ""
    };

    showAll = false;
    mes = csdMes;
    mesZoom = csdMesZoom;
    dia = csdDia;
    noche = csdNoche;
    desq = csdDesq;
    mueNoche = csdMueNoche;
    mueNocheDesq = csdMueNocheDesq;
    desqual1 = csdDesqual1;
    desqual2 = csdDesqual2;
    desqual3 = csdDesqual3;
    CIE1 = csdCIE1;
    CIE2 = csdCIE2;
    test: any = [];

    mounted() {
        this.callBack();
    }

    /**
     * Methods
     */

    async callBack() {
        try {
            const testResp = await this.$api.predictionTest<rdsa>(
                "parametro1",
                "parametro1"
            );
            if (testResp.ok) {
                this.test = testResp.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    onFilterUpdated() {
        if (!Vue.$cookies.isKey(TOKEN)) {
            this.$router.push("/signin");
        }
    }

    formatDates() {
        if (this.filterForm?.start && this.filterForm.end)
            return (
                new Date(this.filterForm?.start).toDateString() +
                " - " +
                new Date(this.filterForm.end).toDateString()
            );
        return "";
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
}
